import React from 'react';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';

import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import SectionFooter from '../PageBuilder/SectionBuilder/SectionFooter';

import css from './LandingPage.module.css';

import SectionHero from './sections/SectionHero/SectionHero';
import SectionWhy from './sections/SectionWhy/SectionWhy';
import SectionConnect from './sections/SectionConnect/SectionConnect';
import SectionFindUniqueHousesits from './sections/SectionFindUniqueHousesits/SectionFindUniqueHousesits';
import SectionHowItWorks from './sections/SectionHowItWorks/SectionHowItWorks';
import SectionListings from './sections/SectionListings/SectionListings';
import SectionPopularDestinations from './sections/SectionPopularDestinations/SectionPopularDestinations';
import SectionCollaborations from './sections/SectionCollaborations/SectionCollaborations';
import SectionFindPerfectSitters from './sections/SectionFindPerfectSitters/SectionFindPerfectSitters';
import SectionTravel from './sections/SectionTravel/SectionTravel';
import SectionButtons from './sections/SectionButtons/SectionButtons';
import { isScrollingDisabled } from '../../ducks/ui.duck';

export const LandingPageComponent = props => {
  const {
    listings,
    scrollingDisabled,
  } = props;

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<SectionFooter />}
        mainColumnClassName={css.mainColumnClassName}
      >
        <SectionHero />
        <SectionWhy />
        <SectionFindUniqueHousesits />
        <SectionConnect />
        <SectionListings
          listings={listings}
        />
        <SectionHowItWorks />
        <SectionPopularDestinations />
        <SectionCollaborations />

        {/* <SectionFindPerfectSitters /> */}
        {/* <SectionTravel /> */}
        {/* <SectionButtons /> */}
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { inProgress, error } = state.hostedAssets || {};
  const { listings } = state.LandingPage;

  return {
    listings,
    inProgress,
    error,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
