import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, H3 } from "../../../../components";

import css from './SectionCollaborations.module.css';
import HNS from './img/nhs.png';
import NARPO from './img/narpo.png';
import Hand from './img/hand.png';

const SectionCollaborations = props => {
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                <FormattedMessage id="SectionCollaborations.mainTitle" />
            </H2>
            <p className={css.description}>
                <FormattedMessage id="SectionCollaborations.info" />
            </p>
            <section className={css.container}>
                <div className={css.card}>
                    <a href="https://www.healthstaffdiscounts.co.uk" target="_blank">
                        <img src={HNS} className={css.icon} />
                    </a>
                    <H3 className={css.cardTitle}>
                        <FormattedMessage id="SectionCollaborations.card1.title" />
                    </H3>

                    <p className={css.info}>
                        <FormattedMessage id="SectionCollaborations.card1.info" />
                        <a href="https://www.healthstaffdiscounts.co.uk" target="_blank">
                            <FormattedMessage id="SectionCollaborations.here" />
                        </a>
                    </p>
                </div>
                <div className={css.card}>
                    <a href="mailto: lamia@housesitmatch.com" target="_blank">
                        <img src={Hand} className={css.icon} />
                    </a>
                    <H3 className={css.cardTitle}>
                        <FormattedMessage id="SectionCollaborations.card2.title" />
                    </H3>
                    <p className={css.info}>
                        <FormattedMessage id="SectionCollaborations.card2.info" />
                    </p>
                </div>
                <div className={css.card}>
                    <a href="mailto: lamia@housesitmatch.com" target="_blank">
                        <img src={NARPO} className={css.icon} />
                    </a>
                    <H3 className={css.cardTitle}>
                        <FormattedMessage id="SectionCollaborations.card3.title" />
                    </H3>
                    <p className={css.info}>
                        <FormattedMessage id="SectionCollaborations.card3.info" />
                        <a href="mailto: lamia@housesitmatch.com" target="_blank">
                            <FormattedMessage id="SectionCollaborations.here" />
                        </a>
                    </p>
                </div>
            </section >
        </div>
    );
};

export default SectionCollaborations;