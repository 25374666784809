import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';

import css from './SectionHero.module.css';
import HeroImage from './img/HeroImage.png';
import stars from './img/stars.png';
import trustpilot from './img/trustpilot.png';
import hand from './img/Hand Holding Heart.png';

const SectionHero = props => {
    return (
        <div className={css.root}>
            <div className={css.container}>

                <H2 className={css.title}>
                    <FormattedMessage
                        id="SectionHero.title"
                        values={{ lineBreak: <br /> }}
                    />
                </H2>

                <span className={css.info}>
                    <FormattedMessage id="SectionHero.info" />
                </span>

                <NamedLink
                    className={css.ctaButton}
                    name="SignupPage"
                >
                    <FormattedMessage id="SectionHero.join" />
                    <img src={ContinueIcon} className={css.icon} />
                </NamedLink>

                <span className={css.info}>
                    <FormattedMessage id="SectionHero.findInfo" />
                </span>
                <div className={css.reviews}>
                    <span className={css.excellent}>
                        <FormattedMessage id="SectionHero.excellent" />
                    </span>
                    <img src={stars} />
                    <img src={trustpilot} />
                </div>
            </div>
        </div>
    );
};

export default SectionHero;
