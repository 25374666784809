import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { NamedLink } from '../../../../components';

import css from "./SectionConnect.module.css";
import SectionImage from './img/Frame 1.png';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';


const SectionConnect = props => {
    return (
        <div className={css.root}>
            <div className={css.container}>

                <div className={css.content}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionConnect.title" />
                    </p>
                    <p className={css.info}>
                        <i style={{ fontSize: 18 }}>
                            “<strong>I have had help</strong> from start to finish with Housesit Match. I joined the community, posted a request for a house and dog sitter and then went through the procedure of choosing someone to finally going on holiday. I was supported all the way. The{" "}
                            <span className={css.gold}>
                                house sitter was excellent
                            </span>{" "}
                            . I had total confidence which made my life so much easier.{" "}
                            <span className={css.gold}>My dogs loved her too</span>.”
                        </i>
                        <br />
                        <br />
                        <span style={{ color: "var(--marketplaceColor)" }}>
                            Nancy, Dog owner in the UK
                        </span>

                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SearchHouseSitPage"
                    >
                        <FormattedMessage id="SectionConnect.link" />
                        <img src={ContinueIcon} className={css.icon} />
                    </NamedLink>
                </div>
                <img src={SectionImage} className={css.image} />
            </div>

        </div>
    );
};

export default SectionConnect;