import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from "../../../../components";

import trophy from './img/trophy 10 years 1.png'
import medal from './img/medal 1.png'
import paw from './img/paw support 1.png'

import css from './SectionWhy.module.css';

const SectionWhy = props => {
    return (
        <div className={css.root}>
            <H2 className={css.title}>
                <FormattedMessage id="SectionWhy.mainTitle" />
            </H2>
            <section className={css.container}>
                <div className={css.card}>
                    <img src={trophy} className={css.icon} />
                    <H2 className={css.cardTitle}>
                        <FormattedMessage id="SectionWhy.card1.title" />
                    </H2>
                    <p className={css.description}>
                        <FormattedMessage id="SectionWhy.card1.description.part1" />
                        <span className={css.gold}>
                            <FormattedMessage id="SectionWhy.card1.description.part2" />
                        </span>
                        <FormattedMessage id="SectionWhy.card1.description.part3" />
                    </p>
                </div>
                <div className={css.card}>
                    <img src={medal} className={css.icon} />
                    <H2 className={css.cardTitle}>
                        <FormattedMessage id="SectionWhy.card2.title" />
                    </H2>
                    <p className={css.description}>
                        <FormattedMessage id="SectionWhy.card2.description.part1" />
                        <span className={css.gold}>
                            <FormattedMessage id="SectionWhy.card2.description.part2" />
                        </span>
                        <FormattedMessage id="SectionWhy.card2.description.part3" />
                    </p>
                </div>
                <div className={css.card}>
                    <img src={paw} className={css.icon} />
                    <H2 className={css.cardTitle}>
                        <FormattedMessage id="SectionWhy.card3.title" />
                    </H2>
                    <p className={css.description}>
                        <FormattedMessage id="SectionWhy.card3.description.part1" />
                        <span className={css.gold}>
                            <FormattedMessage id="SectionWhy.card3.description.part2" />
                        </span>
                        <FormattedMessage id="SectionWhy.card3.description.part3" />
                        <br></br>
                        <span>
                            <FormattedMessage id="SectionWhy.card3.description.part4" />
                        </span>
                    </p>

                </div>
            </section >
        </div >
    );
};

export default SectionWhy;