import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';

import css from "./SectionFindUniqueHousesits.module.css";
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';
import SectionImage from './img/Frame 3.png';

const SectionFindUniqueHousesits = props => {
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                <FormattedMessage id="SectionFindUniqueHousesits.mainTitle" />
            </H2>
            <div className={css.container}>
                <img src={SectionImage} className={css.image} />

                <div className={css.content}>
                    <p className={css.title}>
                        <FormattedMessage id="SectionFindUniqueHousesits.title" />
                    </p>
                    <p className={css.info}>
                        <i style={{ fontSize: 18 }}>
                            “I had the most{" "}
                            <span className={css.gold}>
                                wonderful time
                            </span>{" "}
                            caring for Molly, a beautiful, sweet, clever Labrador in Chesham, UK. Molly was a joy to care for and she was affectionate, playful and loved her walks and ball throwing and tug-o-war. Chesham is a{" "}
                            <span className={css.gold}>
                                beautiful area
                            </span>{" "}
                            with lovely fields for dog walking and Jan is kind and considerate and her cottage is{" "}
                            <span className={css.gold}>
                                lovely and comfortable
                            </span>
                            . I look forward to a return sit very soon.”
                        </i>
                        <br />
                        <br />
                        <span style={{ color: "var(--marketplaceColor)" }}>
                            Barbara, House & pet sitter from Australia
                        </span>
                    </p>
                    <NamedLink
                        className={css.ctaButton}
                        name="SearchHouseSitPage"
                    >
                        <FormattedMessage id="SectionFindUniqueHousesits.link" />
                        <img src={ContinueIcon} className={css.icon} />
                    </NamedLink>
                </div>
            </div>

        </div>
    );
};

export default SectionFindUniqueHousesits;