import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2, NamedLink } from '../../../../components';

import css from "./SectionHowItWorks.module.css";
import SectionImage from './img/video.png';
import logo from './img/HSM logomark 1.png'

const SectionHowItWorks = props => {
    return (
        <div className={css.root}>
            <div className={css.content}>
                <H2 className={css.title}>
                    <img src={logo} className={css.logo} />
                    <FormattedMessage id="SectionHowItWorks.title" />
                </H2>
                <p className={css.info}>
                    Learn more about how and why housesitting and pet sitting works so well through HouseSit Match
                </p>
            </div>
            <div className={css.iframeContainer}>
                {/* <img src={SectionImage} /> */}
                <iframe
                    src="https://www.youtube.com/embed/bG2Z-br0hPs?si=Q0LfwRruMARA2XCR"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
            </div>
        </div>
    );
};

export default SectionHowItWorks;