import React from "react";

import { H2, ListingCard } from "../../../../components";
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SectionListings.module.css';

const SectionListings = props => {
    const {
        listings,
    } = props;

    return (
        <div className={css.root}>
            <H2 className={css.title}>
                <FormattedMessage id="SectionListings.mainTitle" />
            </H2>
            <p className={css.description}>
                <FormattedMessage id="SectionListings.info" />
            </p>

            <section className={css.container}>
                {listings.map(l => (
                    <ListingCard
                        className={css.listing}
                        key={l.id.uuid}
                        listing={l}
                    />
                ))}
            </section>
        </div>
    );
};

export default SectionListings;