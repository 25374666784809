import React from "react";
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from "../../../../components";

import css from './SectionPopularDestinations.module.css';
import UK from './img/uk.png';
import Spain from './img/spain.png';
import France from './img/france.png';

const SectionPopularDestinations = props => {
    return (
        <div className={css.root}>
            <H2 className={css.mainTitle}>
                <FormattedMessage id="SectionPopularDestinations.mainTitle" />
            </H2>
            <p className={css.description}>
                <FormattedMessage id="SectionPopularDestinations.info" />
            </p>
            <section className={css.container}>
                <a href="https://www.housesitmatch.com/house-sitting-uk/" target="_blank">
                    <div className={css.card}>
                        <img src={UK} />
                        <p className={css.cardTitle}>
                            <FormattedMessage id="SectionPopularDestinations.card1.title" />

                        </p>
                    </div>
                </a>
                <a href="https://www.housesitmatch.com/blog/travelling-and-housesitting-in-spain-heres-what-i-love/" target="_blank">
                    <div className={css.card}>
                        <img src={Spain} />
                        <p className={css.cardTitle}>
                            <FormattedMessage id="SectionPopularDestinations.card2.title" />

                        </p>
                    </div>
                </a>
                <a href="https://www.housesitmatch.com/blog/france-by-housesitting-top-things-2-see/" target="_blank">
                    <div className={css.card}>
                        <img src={France} />
                        <p className={css.cardTitle}>
                            <FormattedMessage id="SectionPopularDestinations.card3.title" />

                        </p>
                    </div>
                </a>
            </section>
        </div >
    );
};

export default SectionPopularDestinations;